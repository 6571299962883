<script setup>
import { onMounted } from "vue";
import Products from "./Products.vue";
import PageTitleCard from "@/components/PageTitleCard.vue";
import { setPageTitle } from "../../utils/pageTitle";
import { useRouter } from "vue-router";

const router = useRouter()

onMounted(() => {
  setPageTitle("Products");
});

const addNewProduct = () => {
  router.push("/products/new");
};
</script>

<template>
  <PageTitleCard justifyCard="space-between">
    <template #actions>
      <div class="d-flex-end ml-auto mr-2">
        <v-btn flat class="font-weight-bold" color="primary" @click="addNewProduct"
          >New Product</v-btn
        >
      </div>
    </template>
  </PageTitleCard>
  <div id="products-grid-wrapper">
    <Products />
  </div>
</template>

<style scoped>
#products-grid-wrapper {
  height: 100%;
  padding-top: 0.25%;
}
</style>
